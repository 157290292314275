export const ROUTERS = {
    READ_USER: '/',
    ADD_USER: '/adduser',
    READ_USER_DETAIL: '/readuserdetail/:id',
    READ_SERVICE_DETAIL: '/readservicedetail',
    READ_SERVICES: '/readservices',
    ADD_SERVICES: '/addservices',
    READ_HISTORY: '/readhistory',
    READ_ORDER: '/readorder',
    STATISTIC: '/statistic',
    LOGIN: '/login',
    READ_ACCOUNT: '/readaccount',
    ADD_ACCOUNT: '/addaccount',
  }