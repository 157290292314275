export const RoleEnum = {
    ADMIN: 'ADMINISTATOR',
    STAFF: 'STAFF'
}

export const apiUrlV1 = {
    login: "v1/auth/login",
    changePassword: "v1/auth/change-password",
    getListUser: "v1/customers/list-by-name",
    getUserDetail: "v1/customers/list-by-id/",
    addUser: "v1/customers/create",
    getListItem: "v1/items/get-by-name",
    addItem: "v1/items/create",
    updateItem: "v1/items/edit",
    deleteItem: "v1/items/delete",
    updateUser: "v1/customers/edit",
    addOrder: "v1/orders/create",
    addHistory: "v1/history/create",
    getListHistory: "v1/history/get-by-name",
    getListUserHistory: "v1/history/get-by-user",
    deleteUser: "v1/customers/delete",
    getListOrder: "v1/orders/get-by-name",
    getOrderByUser: "v1/orders/get-by-user-id",
    getStatistic: "v1/dashboard/statistic",
    getAdminInfo: "v1/auth/get-admin-info",
    pagePassword: "v1/auth/page-password",
};

export const Genders = [
    `Nam`, `Nữ`
]

export const itemPaymentEnum = {
    MONEY: 'MONEY',
    POINT: 'POINT',
}

export const itemStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
}