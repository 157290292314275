import swal from 'sweetalert';
import history from '../../utils/history';
const initialState = {
    userList: [],
    totalCustomers: 0,
    totalActiveCustomers: 0,
    userDetail: {}
};
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case "ADD_USER_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'ADD_USER_SUCCESS':
            swal("Thêm Thành Công", "", "success").then(value => value && history.push('/'))
            return {
                ...state,
                loading: false,
            };

        case "ADD_USER_FAIL":
            swal(action.payload, "", "error")
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "GET_USER_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_USER_SUCCESS":
            return {
                ...state,
                userList: action.payload.data.data.result,
                totalCustomers: action.payload.data.data.totalCustomers,
                totalActiveCustomers: action.payload.data.data.totalActiveCustomers,
                loading: false,
            };
        case "GET_USER_FAIL":
            swal(action.payload, "", "error")
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "GET_USER_DETAIL_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_USER_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                userDetail: action.payload,
            };


        case "GET_USER_DETAIL_FAIL":
            swal(action.payload, "", "error")
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case "UPDATE_TREATMENT_FAIL":
            swal(action.payload, "", 'error')
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "UPDATE_USER_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_USER_SUCCESS":
            const updateUserInfo = state.userList
            updateUserInfo.forEach(item => {
                if (item.id === parseInt(action.payload.id)) {
                    item.name = action.payload.name;
                    item.address = action.payload.address;
                    item.phone = action.payload.phone;
                    item.note = action.payload.note;
                    item.pathological = action.payload.pathological
                }
            })
            swal("Cập Nhật Thông Tin Thành Công", "", 'success')
            return {
                ...state,
                userList: updateUserInfo,
                loading: false,
            };
        case "UPDATE_USER_FAIL":
            swal(action.payload, "", "error")
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "CREATE_INVOICE_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'CREATE_INVOICE_SUCCESS':
            swal("Tạo hóa đơn thành công", "", "success")
            return {
                ...state,
                loading: false,
            };

        case "CREATE_INVOICE_FAIL":
            swal(action.payload, "", "error")
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "DELETE_USER_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_USER_SUCCESS":
            swal("Xóa KH Thành Công", "", "success").then(res => {
                history.push('/')
            });
            return {
                ...state,
                loading: false,
            };
        case "DELETE_USER_FAIL":
            swal(action.payload, "", "error")
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "ADMIN_CHECK_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADMIN_CHECK_SUCCESS":
            let adminCheck
            let accountEmail
            let accountStatus
            if (action.payload.length > 0) {
                adminCheck = true;
                accountEmail = action.payload[0].email;
                accountStatus = action.payload[0].trangthai
            } else {
                adminCheck = false;
            }
            return {
                ...state,
                check: adminCheck,
                email: accountEmail,
                trangthai: accountStatus,
                loading: false,
            };
        case "ADMIN_CHECK_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "REMOVE_USER_ACTION":
            return {
                ...state,
                userList: [],
            };

        default: {
            return state;
        }
    }
}