import axios from 'axios'
import { httpDelete, httpGet, httpPost } from '../../services/http.service';
import { apiUrlV1 } from '../../utils/constant';
import { getUserOrderAction } from './services.action';

export const addUserAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_USER_REQUEST" });
    const data = await httpPost(apiUrlV1.addUser, {
      name: params.name,
      date_of_birth: params.date_of_birth,
      address: params.address,
      note: params.note,
      gender: params.gender,
      pathological: params.pathological,
      phone: params.phone,
      referral_code: params.referral_code,
    })
    dispatch({
      type: "ADD_USER_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "ADD_USER_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserAction = (keyword, sort, order, page, limit) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USER_REQUEST" });
    const data = await httpPost(apiUrlV1.getListUser, { keyword: keyword ?? "", sort, order, page, limit })
    dispatch({
      type: "GET_USER_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_USER_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USER_DETAIL_REQUEST" });
    const data = await httpGet(apiUrlV1.getUserDetail + id)
    dispatch({
      type: "GET_USER_DETAIL_SUCCESS",
      payload: data.data.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_USER_DETAIL_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const updateUserAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_USER_REQUEST" });
    await httpPost(apiUrlV1.updateUser, {
      id: params.id,
      name: params.name,
      address: params.address,
      phone: params.phone,
      note: params.note,
      pathological: params.pathological,
      date_of_birth: params.date_of_birth,
      gender: params.gender,
    })
    dispatch({
      type: "UPDATE_USER_SUCCESS",
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: "UPDATE_USER_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createInvoiceAction = (IDKH, IDDV, paid) => async (dispatch) => {
  try {
    dispatch({ type: "CREATE_INVOICE_REQUEST" });
    await httpPost(apiUrlV1.addOrder, { client_id: IDKH, item_id: IDDV, paid })
    await dispatch(getUserAction())
    dispatch(getUserDetailAction(IDKH))
    dispatch(getUserOrderAction(IDKH));
    dispatch({
      type: "CREATE_INVOICE_SUCCESS",
      payload: IDKH
    });
  } catch (error) {
    dispatch({
      type: "CREATE_INVOICE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const deleteUserAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_USER_REQUEST" });
    await httpDelete(`${apiUrlV1.deleteUser}/${id}`);
    dispatch({
      type: "DELETE_USER_SUCCESS",
      payload: { id },
    });
  } catch (error) {
    dispatch({
      type: "DELETE_USER_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const adminCheckAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: "ADMIN_CHECK_REQUEST" });
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admincheck/${id}`);
    dispatch({
      type: "ADMIN_CHECK_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "ADMIN_CHECK_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeUserAction = () => {
  return {
    type: 'REMOVE_USER_ACTION',
  }
}

