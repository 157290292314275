//CSS
import { Table, Space, Button, Input, Modal, InputNumber, Select, Tag, Checkbox, } from 'antd';

import swal from 'sweetalert';
//REACT && REDUX
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getActiveServicesAction, getUserAction, removeServicesAction, removeUserAction, updateTreatmentAction } from "../../../redux/actions";
import history from "../../../utils/history";
// Img
import male_ava from '../../../imgs/male-avatar.png'
import female_ava from '../../../imgs/female-avatar.png'
import { BsCalendarCheckFill, BsFillCalendarFill, BsList } from "react-icons/bs";

export default function ReadUser() {
    //STATE
    const [sortDebtOrder, setSortDebtOrder] = useState('normal');
    const [sortPointOrder, setSortPointOrder] = useState('normal');
    const [sortByColumn, setSortByColumn] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [treatment, setTreament] = useState({});
    const [paid, setPaid] = useState(0);
    const [balance, setBalance] = useState(0);
    const [orders, setOrders] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [patientName, setPatientName] = useState('');

    ////
    const dispatch = useDispatch();
    const getAdminContent = useSelector(state => state.userReducer);
    const { userList, totalActiveCustomers, loading } = getAdminContent;

    useEffect(() => {
        dispatch(getUserAction(
            keyword,
            sortByColumn,
            sortByColumn === 'debt' ? sortDebtOrder : sortPointOrder,
            page,
            limit
        ));
        dispatch(getActiveServicesAction())
        return () => {
            dispatch(removeUserAction())
            dispatch(removeServicesAction())
        }
    }, [page, limit, dispatch, keyword, sortByColumn, sortDebtOrder, sortPointOrder]);


    const navigateUserDetail = (item) => {
        history.push(`/readuserdetail/${item.id}`)
    };

    const columns = [
        {
            title: 'Nợ',
            key: 'debt',
            align: 'center',
            render: (record) => (
                record.total_paid - record.total_unit_price === 0
                    ? <BsList fontSize={30} color='faad14' />
                    : record.total_paid - record.total_unit_price > 0
                        ? <BsList fontSize={30} color='green' />
                        : <BsList fontSize={30} color='red' />
            ),
            sorter: (a, b) => {
                setSortByColumn('debt')
                if (sortDebtOrder === 'desc') {
                    setSortDebtOrder('asc');
                } else if (sortDebtOrder === 'asc') {
                    setSortDebtOrder('normal');
                } else {
                    setSortDebtOrder('desc');
                }
                return 0;
            },
        },
        {
            title: 'Giới tính',
            key: 'gender',
            align: 'center',
            render: (record) => (
                record.gender === 'Nam'
                    ?
                    <img src={male_ava} alt='male-ava' width={50} />
                    :
                    <img src={female_ava} alt='female-ava' width={50} />
            ),
        },
        {
            title: 'Họ Tên',
            key: 'name',
            align: 'center',
            render: (record) => (
                <p onClick={() => navigateUserDetail(record)}>{record.name}</p>
            ),
        },
        {
            title: 'Action',
            key: 'id',
            render: (record) => (
                <Space size="middle">
                    {
                        new Date(record.updated_at).getDate() === new Date().getDate()
                            ? <BsCalendarCheckFill color={'#eb2f96'} onClick={(event) => updateTreatment(event, record)} style={{ fontSize: '25px', marginLeft: '20px', cursor: 'pointer' }} />
                            : <BsFillCalendarFill color={'#eb2f96'} onClick={(event) => updateTreatment(event, record)} style={{ fontSize: '25px', marginLeft: '20px', cursor: 'pointer' }} />
                    }
                    <Tag color={'blue'}>{record.reward_point}</Tag>
                </Space>
            ),
            sorter: (a, b) => {
                setSortByColumn('point')
                if (sortPointOrder === 'desc') {
                    setSortPointOrder('asc');
                } else if (sortPointOrder === 'asc') {
                    setSortPointOrder('normal');
                } else {
                    setSortPointOrder('desc');
                }
                return 0;
            },
        },
    ];

    const updateTreatment = (e, record) => {
        e.stopPropagation();
        const filterOrder = record.orders.filter(item => item.treatment_progress < item.total_treatment)
        if (filterOrder.length > 0) {
            setBalance(record.total_paid - record.total_unit_price)
            setOrders(filterOrder);
            setSelectedService(filterOrder[0].id);
            setPatientName(record.name)
            setIsModalOpen(true);
        } else {
            swal('Khách hàng chưa tạo dịch vụ', '', 'warning')
        }
    }

    const handleOk = () => {
        if (!treatment.id) {
            swal('Please select a service before process', '', 'warning');
            return;
        }
        setPage(1);
        dispatch(updateTreatmentAction(treatment.id, parseInt(paid * 1000)), null, checked);
        setTreament({});
        setSelectedService(null);
        setIsModalOpen(false);
    };

    const handleChangeService = (value) => {
        if(!value) return;
        const selectedOrder = orders.filter(item => item.id === value);
        setTreament({
            id: selectedOrder[0].id,
            item_name: selectedOrder[0].item_name,
            totalPrice: selectedOrder[0].price,
            paid: selectedOrder[0].paid,
            unpaid: selectedOrder[0].price - selectedOrder[0].paid,
            balance: balance,
            total_treatment: selectedOrder[0].total_treatment,
            treatment_progress: selectedOrder[0].treatment_progress,
            current_progress: selectedOrder[0].current_progress,
        })
    }

    const onCancel = () => {
        setIsModalOpen(false);
        setOrders([]);
        setTreament({});
        setSelectedService(null);
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    useEffect(() => {
        handleChangeService(selectedService);
    }, [selectedService])

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h1 style={{ fontSize: '25px' }}>Quản Lý Khách Hàng</h1>
                <Input.Search
                    placeholder="Nhập họ tên khách hàng"
                    style={{ width: "70%", marginBottom: 20 }}
                    allowClear
                    enterButton="Tìm Kiếm"
                    size="large"
                    onSearch={(value) => setKeyword(value)}
                />
                <Button type="primary" style={{ marginBottom: 30, width: 150 }} onClick={() => history.push('/adduser')}>Thêm Khách Hàng</Button>
            </div>
            <Table
            
                loading={loading}
                dataSource={userList}
                columns={columns}
                pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    current: page,
                    total: totalActiveCustomers,
                    onChange: (value) => setPage(value),
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    onShowSizeChange: (current, pageSize) => setLimit(pageSize)
                }}
            />

            <Modal title="Điểm Danh Và Thanh Toán" visible={isModalOpen} onOk={handleOk} onCancel={onCancel}>
                <h4>{patientName}</h4>
                <h4>Số dư: <b>{parseInt(balance)?.toLocaleString()}</b></h4>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <h4 style={{ marginRight: '5px' }}>Chọn DV: </h4>
                    <Select
                        style={{ width: '70%' }}
                        value={selectedService} // Use the selectedService state to control the value of the Select component
                        onChange={(value) => { setSelectedService(value); handleChangeService(value); }}
                        options={orders.map(item => {
                            return {
                                value: item.id,
                                label: item.item_name
                            }
                        })}
                    />
                </div>

                {treatment.id &&
                    <div style={{ marginTop: 8 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                            <div>
                                <h4>Tổng tiền: <b>{treatment.totalPrice?.toLocaleString()}</b></h4>
                                <h4>Đã trả: <b>{treatment.paid?.toLocaleString()}</b></h4>
                                <h4>Còn lại: <b>{treatment.unpaid?.toLocaleString()}</b></h4>
                            </div>
                            <div>
                                <h4>Số buổi: <Tag>{treatment.treatment_progress}</Tag>/ <Tag>{treatment.total_treatment}</Tag></h4>
                                <Checkbox onChange={onChange}>Không đi</Checkbox>
                            </div>
                        </div>

                        <InputNumber
                            style={{ width: '100%' }}
                            max={10000000}
                            step={1000}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(value) => setPaid(value)}
                        />
                    </div>
                }
            </Modal>
        </>
    )
}