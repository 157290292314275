import { Button, Form, Select, Input, InputNumber, } from 'antd';
//REACT && REDUX
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { updateServicesAction } from '../../../redux/actions';
import { itemPaymentEnum, itemStatusEnum } from '../../../utils/constant';
import history from '../../../utils/history';
import { ROUTERS } from '../../../constants/router';

export default function ReadServicesDetail() {
    const dispatch = useDispatch();
    const getAdminContent = useSelector(state => state.servicesReducer);
    const { serviceDetail } = getAdminContent;
    const [newsForm] = Form.useForm();

    useEffect(() => {
        newsForm.resetFields();
    }, [serviceDetail.id, newsForm])


    function editServices(value) {
        const newValue = {
            ...value,
            id: serviceDetail.id,
        }
        dispatch(updateServicesAction(newValue))
    }

    return (
        <>
            <div style={{ textAlign: 'center', width: '100%' }}>
                <h1 style={{ fontSize: '25px' }}>Chi Tiết Dịch Vụ</h1>
                <Button type="primary" style={{ marginBottom: 30, width: 150 }} onClick={() => history.push(ROUTERS.ADD_SERVICES)}>Thêm Dịch Vụ</Button>
            </div>
            <Form
                layout="vertical"
                initialValues={{
                    name: serviceDetail?.name,
                    code: serviceDetail?.code,
                    status: serviceDetail?.status,
                    price: serviceDetail?.price,
                    unit_price: serviceDetail?.unit_price,
                    reward_point: serviceDetail?.reward_point ?? 0,
                    number_of_treatments: serviceDetail?.number_of_treatments,
                    payment: serviceDetail?.payment,
                }}
                onFinish={editServices}
                scrollToFirstError
                form={newsForm}
                style={{ padding: 15 }}
            >

                <Form.Item

                    className="form-in"
                    label="Tên Dịch Vụ: "
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền tên dịch vụ',
                        },
                    ]}
                >
                    <Input placeholder="Nhập tên dịch vụ..." />
                </Form.Item>
                <Form.Item

                    className="form-in"
                    label="Mã Dịch Vụ: "
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền mã dịch vụ',
                        },
                    ]}
                >
                    <Input disabled placeholder="Mã dịch vụ nên là các chữ cái đầu của tên dịch vụ..." />
                </Form.Item>
                <Form.Item

                    label="Trạng Thái"
                    name="status"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng chọn trạng thái...',
                        },
                    ]}
                >
                    <Select placeholder="Chọn trạng thái...">
                        <Select.Option value={itemStatusEnum.ACTIVE}>Kích Hoạt</Select.Option>
                        <Select.Option value={itemStatusEnum.INACTIVE}>Không Kích Hoạt</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Loại Thanh Toán"
                    name="payment"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng chọn hình thức thanh toán...',
                        },
                    ]}
                >
                    <Select
                        placeholder="Hình thức thanh toán..."
                        disabled
                    >
                        <Select.Option value={itemPaymentEnum.MONEY}>VNĐ</Select.Option>
                        <Select.Option value={itemPaymentEnum.POINT}>Điểm</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Đơn Giá Dịch Vụ: "
                    name="unit_price"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập đơn giá dịch vụ...',
                        },

                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        min={1}
                        max={10000000}
                        formatter={value => `VNĐ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/VNĐ\s?|(,*)/g, '')}
                    />
                </Form.Item>
                <Form.Item
                    label="Giá Dịch Vụ: "
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập giá dịch vụ...',
                        },

                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        min={1}
                        max={10000000}
                        formatter={value => `VNĐ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/VNĐ\s?|(,*)/g, '')}
                        disabled={serviceDetail.payment === itemPaymentEnum.POINT}
                    />
                </Form.Item>

                <Form.Item
                    label="Điểm tích lũy: "
                    name="reward_point"
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        max={1000}
                        formatter={value => `Điểm ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/Điểm\s?|(,*)/g, '')}
                        disabled={serviceDetail.payment === itemPaymentEnum.MONEY}
                    />
                </Form.Item>

                <Form.Item
                    label="Số Lần Điều Trị: "
                    name="number_of_treatments"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập số lần điều trị...',
                        },

                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        min={1}
                        max={10}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Sửa Dịch Vụ</Button>
                </Form.Item>
            </Form >
        </>
    )
}