import { Table, Tag, Space, Input, Modal } from 'antd';
import swal from 'sweetalert';
//REACT && REDUX
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { getOrderAction, removeServicesAction } from '../../redux/actions/services.action';
import { removeVerifyAction, verifyPassword } from '../../redux/actions/account.action';
import moment from 'moment';

const { Column } = Table;

export default function ReadOrder() {
    const getAdminContent = useSelector(state => state.servicesReducer);
    const accountReducer = useSelector(state => state.accountReducer);
    const { orderList, totalOrders } = getAdminContent;
    const { isVerify } = accountReducer;

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [secureKey, setSecureKey] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [contentUnlocked, setContentUnlocked] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrderAction(keyword, page, limit));
        return () => {
            dispatch(removeServicesAction())
            dispatch(removeVerifyAction())
        }
    }, [dispatch, keyword, page, limit, contentUnlocked]);

    useEffect(() => {
        if (isVerify) {
            setContentUnlocked(true);
            setIsModalOpen(false);
        }
    }, [isVerify]);

    orderList.forEach(item => {
        item.getTime = new Date(item.created_at).getTime();
        item.created_at = item.created_at.replace("T", " ").substr(0, 19)
    })

    const handleOk = () => {
        dispatch(verifyPassword(secureKey, 'order'))
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div style={{ textAlign: 'center', width: '100%' }}>
                <h1 style={{ fontSize: '25px' }}>Quản Lý Hóa Đơn</h1>
                <Input.Search
                    placeholder="Nhập họ tên khách hàng"
                    style={{ width: "500px", marginBottom: "30px" }}
                    allowClear
                    enterButton="Tìm Kiếm"
                    size="large"
                    onSearch={(value) => setKeyword(value)}
                />
            </div>
            {contentUnlocked &&
                <Table dataSource={orderList}
                    pagination={{
                        position: ['topCenter', 'bottomCenter'],
                        defaultCurrent: 1,
                        total: totalOrders,
                        onChange: (value) => setPage(value),
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        onShowSizeChange: (current, pageSize) => setLimit(pageSize)
                    }}
                >
                    <Column
                        title="Ngày Tạo"
                        key="created_at"
                        render={(text, record) => (
                            moment.utc(record.created_at).utcOffset(7).format('YYYY-MM-DDTHH:mm:ss')
                        )}
                    />
                    <Column title="Họ Tên" dataIndex="customer_name" key="customer_name" />
                    <Column title="Tên Dịch Vụ" dataIndex="item_name" key="item_name" />
                    <Column title="Mã Dịch Vụ" dataIndex="item_code" key="item_code" />
                    <Column
                        title="Giá Tiền"
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <Tag color="gold">{record.order_price.toLocaleString("vi-vn")} VNĐ</Tag>
                            </Space>
                        )}
                    />
                </Table>
            }
            <Modal title="Nhập mật khẩu" visible={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
                <Input.Password placeholder='nhập mật khẩu...' onChange={(e) => setSecureKey(e.target.value)} />
            </Modal>
        </>
    )
}