import { useEffect, useState } from 'react';
import { Select, Statistic, Row, Col, Modal, Input } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import './styles.css';
import swal from 'sweetalert';
//REDUX && REACT
import { useSelector, useDispatch } from "react-redux";
import { getStatisticAction, removeVerifyAction, verifyPassword } from '../../redux/actions';
export default function StatisticManager() {
    const dispatch = useDispatch();
    const getAdminContent = useSelector(state => state.statisticReducer);
    const accountReducer = useSelector(state => state.accountReducer);
    const { statisticData } = getAdminContent;
    const { isVerify } = accountReducer;

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [secureKey, setSecureKey] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [contentUnlocked, setContentUnlocked] = useState(false);

    useEffect(() => {
        if (contentUnlocked) {
            dispatch(getStatisticAction(month, year));
        }
        return () => {
            dispatch(removeVerifyAction())
        }
    }, [dispatch, month, year, contentUnlocked]);

    useEffect(() => {
        if (isVerify) {
            setContentUnlocked(true);
            setIsModalOpen(false);
        }
    }, [isVerify]);


    const handleOk = () => {
        dispatch(verifyPassword(secureKey, 'statistic'))
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal title="Nhập mật khẩu" visible={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
                <Input.Password type={'password'} placeholder='nhập mật khẩu...' onChange={(e) => setSecureKey(e.target.value)} />
            </Modal>
            {contentUnlocked &&
                <>
                    <Row gutter={16} className="total-statistic">
                        <Col span={12} className="total-user">
                            <Statistic title="Số Lượng Người Dùng" value={statisticData.totalCustomer} />
                        </Col>
                        <Col span={12} className="total-credit">
                            <Statistic title="Tổng Doanh Thu (VND)" value={statisticData.totalPaid?.total_paid} precision={2} />
                        </Col>
                    </Row>
                    <div>
                        <Select style={{ marginRight: 20 }} placeholder="Chọn năm" onChange={(value) => setYear(value)} value={year}>
                            {statisticData.statisticYear?.map(item => {
                                return <Select.Option value={item}>Năm {item}</Select.Option>
                            })}
                        </Select>
                        <Select placeholder="Chọn tháng" onChange={(value) => setMonth(value)} value={month}>
                            {statisticData.statisticMonth?.map(item => {
                                return <Select.Option value={item}>Tháng {item}</Select.Option>
                            })}
                        </Select>
                    </div>
                    <div className="row-chart">
                        <div className="row">
                            <div className="chart">
                                <div style={{ paddingBottom: '20px' }}>
                                    <h3>Doanh Thu Theo Tháng Trong Năm</h3>
                                </div>
                                <div className="chart-info">
                                    <BarChart
                                        width={800}
                                        height={400}
                                        data={statisticData.totalPaidPerMonth}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 80,
                                            bottom: 5,
                                        }}
                                        barSize={20}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="totalPaid" fill="#8884d8" />
                                    </BarChart>
                                </div>
                            </div>
                            <div className="chart">
                                <div style={{ paddingBottom: '20px' }}>
                                    <h3>Tổng Số Lượng Đơn Hàng Theo Tháng Trong Năm</h3>
                                </div>
                                <div className="chart-info">
                                    <BarChart
                                        width={800}
                                        height={400}
                                        data={statisticData.totalOrderPerMonth}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 80,
                                            bottom: 5,
                                        }}
                                        barSize={20}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="totalOrder" fill="#FF3333" />
                                    </BarChart>
                                </div>
                            </div>
                        </div>
                        <div className="row-chart">
                            <div className="row">
                                <div className="chart">
                                    <div style={{ paddingBottom: '20px' }}>
                                        <h3>Doanh Thu Theo Ngày Trong Tháng</h3>
                                    </div>
                                    <div className="chart-info">
                                        <BarChart
                                            width={800}
                                            height={400}
                                            data={statisticData.totalPaidPerDay}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 80,
                                                bottom: 5,
                                            }}
                                            barSize={20}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="totalPaid" fill="#009900" />
                                        </BarChart>
                                    </div>
                                </div>
                                <div className="chart">
                                    <div style={{ paddingBottom: '20px' }}>
                                        <h3>Tổng Số Lượng Đơn Hàng Theo Ngày Trong Tháng</h3>
                                    </div>
                                    <div className="chart-info">
                                        <BarChart
                                            width={800}
                                            height={400}
                                            data={statisticData.totalOrderPerDay}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 80,
                                                bottom: 5,
                                            }}
                                            barSize={20}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="totalOrder" fill="#00CCFF" />
                                        </BarChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-chart">
                            <div className="row">
                                <div className="chart">
                                    <div style={{ paddingBottom: '20px' }}>
                                        <h3>Khách Hàng Mới Trong Tháng</h3>
                                    </div>
                                    <div className="chart-info">
                                        <BarChart
                                            width={800}
                                            height={400}
                                            data={statisticData.totalNewCustomerPerMonth}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 80,
                                                bottom: 5,
                                            }}
                                            barSize={20}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="totalCustomer" fill="#009900" />
                                        </BarChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}