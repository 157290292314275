import swal from 'sweetalert';
import { ROUTERS } from '../../constants/router';
import history from '../../utils/history';
const initialState = {
    servicesList: [],
    serviceHistory: [],
    activeServicesList: [],
    orderList: [],
    userOrderList: [],
    serviceDetail: {},
    totalServices: 0,
    totalHistories: 0,
    totalOrders: 0,
};
export default function servicesReducer(state = initialState, action) {
    switch (action.type) {

        case "ADD_SERVICES_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'ADD_SERVICES_SUCCESS':
            if (action.payload.data === "found") {
                swal("Dịch Vụ Đã Tồn Tại", "Vui lòng kiểm tra trên hệ thống", "error")
            } else {
                swal("Thêm Thành Công", "", "success")
            }
            return {
                ...state,
                loading: false,
            };

        case "ADD_SERVICES_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "GET_SERVICES_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'GET_SERVICES_SUCCESS':
            return {
                ...state,
                servicesList: action.payload.data.item,
                totalServices: action.payload.data.totalItems,
                loading: false,
            };

        case "GET_SERVICES_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "GET_SERVICES_DETAIL":
            const newServicesDetail = state.servicesList.filter(item => item.id_dichvu === parseInt(action.payload))
            return {
                ...state,
                serviceDetail: newServicesDetail[0],
            };


        case "GET_SERVICES_HISTORY_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'GET_SERVICES_HISTORY_SUCCESS':
            return {
                ...state,
                serviceHistory: action.payload.data.history,
                totalHistories: action.payload.data.totalHistories,
                loading: false,
            };

        case "GET_SERVICES_HISTORY_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "UPDATE_SERVICES_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'UPDATE_SERVICES_SUCCESS':
            const newServicesList = state.servicesList
            newServicesList.forEach(item => {
                if (item.id === action.payload.id) {
                    item.status = action.payload.status
                    item.name = action.payload.name
                    item.reward_point = action.payload.reward_point
                    item.price = action.payload.price
                    item.number_of_treatments = action.payload.number_of_treatments
                }
            })
            swal("Sửa thành công", "", "success").then((value) => {
                history.push(ROUTERS.READ_SERVICES)
            });
            return {
                ...state,
                servicesList: newServicesList,
                loading: false,
            };

        case "UPDATE_SERVICES_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "GET_ACTIVE_SERVICES_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'GET_ACTIVE_SERVICES_SUCCESS':
            return {
                ...state,
                activeServicesList: action.payload,
                loading: false,
            };

        case "GET_ACTIVE_SERVICES_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "DELETE_SERVICE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_SERVICE_SUCCESS":
            if (action.payload.data === 'error') {
                swal("Xóa Dịch Vụ Thất Bại", "dịch vụ đã được sử dụng, vui lòng chuyển trạng thái dịch vụ", "error");
                return {
                    ...state,
                    loading: false,
                };
            } else {
                const newServiceList = state.servicesList.filter(item => item.id !== action.payload.id)
                swal("Xóa Dịch Vụ Thành Công", "", "success");
                return {
                    ...state,
                    servicesList: newServiceList,
                    loading: false,
                };
            }
        case "DELETE_SERVICE_FAIL":
            swal(`${action.payload}`, "", 'error')
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case "GET_ORDER_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'GET_ORDER_SUCCESS':
            return {
                ...state,
                orderList: action.payload.data.order,
                totalOrders: action.payload.data.totalOrders,
                loading: false,
            };

        case "GET_ORDER_FAIL":
            swal(action.payload, "", 'error')
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "GET_USER_HISTORY_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'GET_USER_HISTORY_SUCCESS':
            return {
                ...state,
                serviceHistory: action.payload.data.history,
                totalHistories: action.payload.data.totalHistories,
                loading: false,
            };

        case "GET_USER_HISTORY_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "GET_USER_ORDER_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'GET_USER_ORDER_SUCCESS':
            return {
                ...state,
                userOrderList: action.payload.data,
                loading: false,
            };

        case "GET_USER_ORDER_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "SET_SERVICE_DETAIL_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case 'SET_SERVICE_DETAIL_SUCCESS':
            const serviceList = state.servicesList;
            const serviceDetail = serviceList.filter(item => item.id === action.payload)[0];
            return {
                ...state,
                serviceDetail: serviceDetail,
                loading: false,
            };

        case "SET_SERVICE_DETAIL_FAIL":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "UPDATE_TREATMENT_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_TREATMENT_SUCCESS":
            swal("Điểm Danh Thành Công", "", 'success')
            return {
                ...state,
                loading: false,
            };

        case "REMOVE_SERVICES_ACTION":
            return {
                ...state,
                servicesList: [],
                activeServicesList: [],
                serviceHistory: [],
                orderList: [],
                userOrderList: []
            };

        default: {
            return state;
        }
    }
}