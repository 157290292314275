import { httpDelete, httpPost } from '../../services/http.service';
import { apiUrlV1, itemStatusEnum } from '../../utils/constant';
import { getUserAction, getUserDetailAction } from './user.action';

export const addServicesAction = (params) => async (dispatch) => {
    try {
        dispatch({ type: "ADD_SERVICES_REQUEST" });
        const data = await httpPost(apiUrlV1.addItem, {
            name: params.name,
            price: params.price,
            unit_price: params.unit_price,
            reward_point: params.reward_point,
            number_of_treatments: params.number_of_treatments,
            payment_method: params.payment_method,
            code: params.code,
        })
        dispatch({
            type: "ADD_SERVICES_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "ADD_SERVICES_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const getServicesAction = (keyword, page, limit) => async (dispatch) => {
    try {
        dispatch({ type: "GET_SERVICES_REQUEST" });

        const { data } = await httpPost(apiUrlV1.getListItem, {
            keyword: keyword ?? '',
            page,
            limit
        })

        dispatch({
            type: "GET_SERVICES_SUCCESS",
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: "GET_SERVICES_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getActiveServicesAction = (keyword, page, limit) => async (dispatch) => {
    try {
        dispatch({ type: "GET_ACTIVE_SERVICES_REQUEST" });

        const { data } = await httpPost(apiUrlV1.getListItem, { keyword: keyword ?? '', page, limit });
        const filterData = data.data.item.filter(item => item.status === itemStatusEnum.ACTIVE);
        dispatch({
            type: "GET_ACTIVE_SERVICES_SUCCESS",
            payload: filterData,
        });
    } catch (error) {
        dispatch({
            type: "GET_ACTIVE_SERVICES_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServicesHistoryAction = (keyword, page, limit) => async (dispatch) => {
    try {
        dispatch({ type: "GET_SERVICES_HISTORY_REQUEST" });

        const { data } = await httpPost(apiUrlV1.getListHistory, {
            keyword: keyword ?? '',
            page,
            limit,
        });

        dispatch({
            type: "GET_SERVICES_HISTORY_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "GET_SERVICES_HISTORY_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateServicesAction = (params) => async (dispatch) => {
    try {
        dispatch({ type: "UPDATE_SERVICES_REQUEST" });
        await httpPost(apiUrlV1.updateItem, {
            id: params.id,
            name: params.name,
            status: params.status,
            price: params.price,
            unit_price: params.unit_price,
            reward_point: params.reward_point,
            number_of_treatments: params.number_of_treatments
        })
        dispatch({
            type: "UPDATE_SERVICES_SUCCESS",
            payload: params,
        });
    } catch (error) {
        dispatch({
            type: "UPDATE_SERVICES_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteServiceAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: "DELETE_SERVICE_REQUEST" });
        const { data } = await httpDelete(`${apiUrlV1.deleteItem}/${id}`)
        const newData = {
            data: data,
            id: id,
        }
        dispatch({
            type: "DELETE_SERVICE_SUCCESS",
            payload: newData,
        });
    } catch (error) {
        dispatch({
            type: "DELETE_SERVICE_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const getOrderAction = (keyword, page, limit) => async (dispatch) => {
    try {
        dispatch({ type: "GET_ORDER_REQUEST" });

        const { data } = await httpPost(apiUrlV1.getListOrder, {
            keyword,
            page,
            limit
        });

        dispatch({
            type: "GET_ORDER_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "GET_ORDER_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getUserHistoryAction = (id, page, limit) => async (dispatch) => {
    try {
        dispatch({ type: "GET_SERVICES_HISTORY_REQUEST" });

        const { data } = await httpPost(apiUrlV1.getListUserHistory, {
            id,
            page,
            limit,
        });

        dispatch({
            type: "GET_SERVICES_HISTORY_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "GET_SERVICES_HISTORY_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getUserOrderAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: "GET_USER_ORDER_REQUEST" });

        const { data } = await httpPost(apiUrlV1.getOrderByUser, {
            id
        });

        dispatch({
            type: "GET_USER_ORDER_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "GET_USER_ORDER_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateTreatmentAction = (orderId, price, userId, notVisit, numOfCheckin) => async (dispatch) => {
    try {
        console.log(numOfCheckin)
        dispatch({ type: "UPDATE_TREATMENT_REQUEST" });
        if (numOfCheckin > 0) {
            for (let i = 0; i <= numOfCheckin; i++) {
                const isLastLoop = i === numOfCheckin;
                await httpPost(apiUrlV1.addHistory, {
                    order_id: orderId,
                    price: isLastLoop ? price : 0,
                    notVisit: isLastLoop ? notVisit : true
                });
            }

        } else {
            await httpPost(apiUrlV1.addHistory, {
                order_id: orderId,
                price,
                notVisit
            })
        }

        if (userId) {
            dispatch(getUserDetailAction(userId));
            dispatch(getUserHistoryAction(userId));
            dispatch(getUserOrderAction(userId));
        } else {
            dispatch(getUserAction(''));
        }
        dispatch({
            type: "UPDATE_TREATMENT_SUCCESS",
            payload: { orderId, price },
        });
    } catch (error) {
        dispatch({
            type: "UPDATE_TREATMENT_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const setServiceDetail = (id) => async (dispatch) => {
    try {

        dispatch({ type: "SET_SERVICE_DETAIL_REQUEST" });

        dispatch({
            type: "SET_SERVICE_DETAIL_SUCCESS",
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: "SET_SERVICE_DETAIL_FAIL",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const getServicesDetailAction = (id) => {
    return {
        type: 'GET_SERVICES_DETAIL',
        payload: id,
    }
}

export const removeServicesAction = () => {
    return {
        type: 'REMOVE_SERVICES_ACTION',
    }
}