import { Route, Link, Redirect } from 'react-router-dom';
import { Layout, Menu, Popover, Tag, Button, Modal } from 'antd';
import MenuAdmin from '../MenuAdmin'
import logo from '../../imgs/logo.png'
import { ROUTERS } from '../../constants/router';
import React, { useState } from 'react';
import { Badge } from 'antd';
import './styles.css'
import { GrNotification } from 'react-icons/gr'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';

//REDUX && REACT
import { useSelector, useDispatch } from "react-redux";

//Components
import ResetPWD from '../resetPwdForm';
import { getAccountAction, logoutAction } from '../../redux/actions';
import { useEffect } from 'react';

const { Header, Content, Sider } = Layout;
function DefaultLayout(props) {
    const dispatch = useDispatch();
    const { defaultKey, openKey, exact, path, component: Component, ...other } = props;
    const getAdminContent = useSelector(state => state.statisticReducer);
    const { checkData } = getAdminContent;
    const [isCollapse, setIsCollapse] = useState(false);


    document.title = "Quản Lý | Thiện Hiếu"

    const getAdminInfo = useSelector((state) => state.accountReducer);
    const { email, isLogged, loading } = getAdminInfo;

    useEffect(() => {
        dispatch(getAccountAction())
    }, [dispatch]);

    // if (!loading) {
    //     if (!isLogged || !email) {
    //         swal("Vui lòng đăng nhập", "", "error")
    //         return <Redirect to="/login" />
    //     }
    // }


    function pushNotify() {
        return (
            <>
                {checkData.length > 0 ? (
                    checkData.map((item, index) => {
                        return (
                            <div key={index} style={{ borderBottom: "1px solid black", marginBottom: '20px' }}>Khách hàng <Tag color="gold">{item.hoten}</Tag>đã {Math.round(item.days)} ngày chưa tái khám</div>
                        )
                    })
                ) : (
                    <div>Không có thông báo</div>
                )}
            </>
        )
    }

    const renderLogo = () => {
        return <div style={{ float: 'left' }}>
            <img width='50px' height='50px' src={logo} alt="loading" onClick={() => { setIsCollapse(!isCollapse) }} style={{ cursor: 'pointer' }} />
        </div>
    }

    return (
        <Route
            exact={exact}
            path={path}
            render={(routeProps) => {
                return (
                    <>
                        <Layout>
                            <Header className="header" style={{ background: '#fff' }}>
                                <Menu mode="horizontal">
                                    {renderLogo()}
                                    <Tag color="#ed9205" style={{ marginLeft: '50px', padding: '20px' }}>{email}</Tag>

                                    {/* <Popover placement="bottomRight" title={"Thông Báo"} content={pushNotify} trigger="click">
                                        <GrNotification style={{ marginLeft: '50px', fontSize: '25px', top: '8px', position: 'relative', cursor: 'pointer' }} />
                                        <Badge count={checkData.length} />
                                    </Popover> */}

                                </Menu>

                            </Header>
                            <Content >
                                <Layout className="site-layout-background" style={{ padding: '24px 0' }} >
                                    {isCollapse &&
                                        <Sider className="site-layout-background" width={70} style={{ background: '#fff', marginRight: 20 }}>
                                            <MenuAdmin defaultKey={defaultKey} openKey={openKey} />
                                        </Sider>
                                    }
                                    <Content style={{ minHeight: 280, background: '#f1f1f1' }}><Component {...other} {...routeProps} /></Content>
                                </Layout>
                            </Content>
                        </Layout>
                    </>
                )
            }}
        />
    );
}

export default DefaultLayout;
