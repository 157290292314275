import { Table, Space, Button, Form, Tag } from 'antd';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import swal from 'sweetalert';
//REACT && REDUX
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { deleteServiceAction, getServicesAction, removeServicesAction, setServiceDetail } from '../../../redux/actions';
import { itemStatusEnum } from '../../../utils/constant';
import history from '../../../utils/history';
import { ROUTERS } from '../../../constants/router';

export default function ReadServices() {
    const dispatch = useDispatch();
    const getAdminContent = useSelector(state => state.servicesReducer);
    const { servicesList, totalServices, loading } = getAdminContent;
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch(getServicesAction('', page, limit));
        return () => {
            dispatch(removeServicesAction())
        }
    }, [dispatch, page, limit]);

    const showDrawer = (item) => {
        dispatch(setServiceDetail(item.id))
        history.push('/readservicedetail')
    };

    function deleteService(id) {
        swal({
            title: "Bạn Có Chắc Chắn Không?",
            text: "Một khi xóa, bạn sẽ không thể khôi phục lại file này",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(deleteServiceAction(id))
                } else {
                    swal("Dữ Liệu Của Bạn Đã Được Bảo Toàn!");
                }
            });
    }
    //Kiến Trúc Bảng
    const columns = [
        { title: 'Tên Dịch Vụ', dataIndex: 'name', key: 'name' },
        { title: 'Mã Dịch Vụ', dataIndex: 'code', key: 'code' },
        {
            title: 'Đơn Giá',
            key: 'unit_price',
            render: (record) => (
                parseInt(record.unit_price).toLocaleString('vi-VN') + " VNĐ"
            ),
        },
        {
            title: 'Giá Tiền',
            key: 'price',
            render: (record) => (
                parseInt(record.price).toLocaleString('vi-VN') + " VNĐ"
            ),
        },
        {
            title: 'Giá Điểm Tích Lũy',
            key: 'reward_point',
            render: (record) => (
                <>
                    {record.giatichluy ? (
                        record.reward_point.toLocaleString('vi-VN') + " Điểm"
                    ) : (
                        "Không"
                    )}
                </>
            ),
        },
        { title: 'Số Lần Điều Trị', dataIndex: 'number_of_treatments', key: 'number_of_treatments' },
        {
            title: 'Trạng Thái',
            key: 'status',
            render: (record) => (
                record.status === itemStatusEnum.ACTIVE ? <Tag color="green">Kích Hoạt</Tag> : <Tag color="red">Ko Kích Hoạt</Tag>
            ),
        },
        {
            title: 'Action',
            key: 'id',
            render: (record) => (
                <Space size="middle">
                    <EditTwoTone twoToneColor="#eb2f96" onClick={() => showDrawer(record)} style={{ fontSize: '25px', marginLeft: '20px' }} />
                    <DeleteTwoTone onClick={() => deleteService(record.id)} twoToneColor='red' style={{ fontSize: '25px' }} />
                </Space>
            ),
        },
    ];
    return (
        <>
            <div style={{ textAlign: 'center', width: '100%' }}>
                <h1 style={{ fontSize: '25px' }}>Quản Lý Dịch Vụ</h1>
                <Button type="primary" style={{ marginBottom: 30, width: 150 }} onClick={() => history.push(ROUTERS.ADD_SERVICES)}>Thêm Dịch Vụ</Button>
            </div>
            <Table dataSource={servicesList} columns={columns}
                loading={loading}
                pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    defaultCurrent: 1,
                    total: totalServices,
                    onChange: (value) => setPage(value),
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    onShowSizeChange: (current, pageSize) => setLimit(pageSize)
                }} />
        </>
    )
}