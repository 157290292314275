import { Button, Collapse, Input, Form, Breadcrumb, Checkbox, Space, Modal, Table, Select, DatePicker, InputNumber, Tag, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ROUTERS } from '../../../constants/router';
import { createInvoiceAction, deleteUserAction, getActiveServicesAction, getUserDetailAction, getUserHistoryAction, getUserOrderAction, removeServicesAction, setUserDetailAction, updateTreatmentAction, updateUserAction } from '../../../redux/actions';
import moment from 'moment';
import { AiOutlineCheck, AiOutlineClose, AiFillCloseSquare } from 'react-icons/ai';
import { Genders } from '../../../utils/constant';
import { DeleteTwoTone } from '@ant-design/icons';
import swal from 'sweetalert';


const ReadUserDetail = () => {
    const { id } = useParams();
    const { Column } = Table;
    const dispatch = useDispatch();
    const getAdminContent = useSelector(state => state.userReducer);
    const { userDetail, loading } = getAdminContent;
    const getServicesContent = useSelector(state => state.servicesReducer);
    const { activeServicesList } = getServicesContent;
    const serviceReducer = useSelector(state => state.servicesReducer);
    const { serviceHistory, totalHistories, userOrderList } = serviceReducer;

    const [checked, setChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [treatment, setTreament] = useState({});
    const [paid, setPaid] = useState(0);
    const [IDDV, setIDDV] = useState(null);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const [newsForm] = Form.useForm()

    function editUser(value) {
        const newValue = {
            ...value,
            id: userDetail.id,
            date_of_birth: value.date_of_birth?.toDate()
        }
        dispatch(updateUserAction(newValue))
    }

    useEffect(() => {
        newsForm.resetFields();
    }, [userDetail, newsForm])

    useEffect(() => {
        dispatch(getUserOrderAction(id));
        dispatch(getUserDetailAction(id))
        return () => {
            dispatch(removeServicesAction())
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUserHistoryAction(id, page, limit));
    }, [dispatch, page, limit]);

    function displayCheckBox(x) {
        let numArr = []
        for (let i = 0; i < x; i++) {
            numArr.push(i)
        }
        return numArr
    }

    const updateTreatment = (record, checked, index) => {
        if (!checked) return;
        setTreament({
            id: record.id,
            totalPrice: parseInt(record.price),
            paid: parseInt(record.paid),
            unpaid: record.price - record.paid,
            numberOfCheckin: index,
        })
        setIsModalOpen(true)
    }

    const newCollums = [
        { title: 'Tên Dịch Vụ', dataIndex: 'item_name', key: 'item_name' },
        { title: 'Mã Dịch Vụ', dataIndex: 'item_code', key: 'item_code' },
        {
            title: 'Số Lần Điều Trị', key: 'total_treatment',
            render: (record) => (
                <Space size='small'><Tag>{record.history.filter(item => item.unit_price !== 0).length}</Tag></Space>
            ),
        },
        {
            title: 'Số Lần Đã Điều Trị',
            key: 'treatment_progress',
            render: (record) => (
                <Space size="middle">
                    {record.history.map((item, index) => {
                        if (parseInt(item.price) === 0 && item.unit_price === 0) {
                            return <AiOutlineClose />;
                        } else if (parseInt(item.price) !== 0 && item.unit_price === 0) {
                            return <AiFillCloseSquare />;
                        } else if (parseInt(item.price) === 0 && item.unit_price !== 0) {
                            return <AiOutlineCheck />;
                        } else {
                            return <Checkbox defaultChecked disabled key={index} />;
                        }

                    })}
                    {displayCheckBox(record.total_treatment - record.history.length).map((item, index) => {
                        return <Checkbox onChange={(e) => updateTreatment(record, e.target.checked, index)} key={index} />
                    })}
                </Space>
            ),
        },
    ];

    const handleOk = () => {
        dispatch(updateTreatmentAction(treatment.id, parseInt(paid * 1000), userDetail.id, checked, treatment.numberOfCheckin));
        setPaid(0);
        setChecked(false);
        setIsModalOpen(false);
    };

    function createInvoice() {
        dispatch(createInvoiceAction(userDetail.id, IDDV, 0))
    }

    useEffect(() => {
        dispatch(getActiveServicesAction())
        return () => {
            dispatch(removeServicesAction())
        }
    }, [dispatch]);

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    function deleteUser(e, id) {
        e.stopPropagation();
        swal({
            title: "Bạn Có Chắc Chắn Không?",
            text: "Một khi xóa, bạn sẽ không thể khôi phục lại tài khoản này",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(deleteUserAction(id))
                } else {
                    swal("Dữ Liệu Của Bạn Đã Được Bảo Toàn!");
                }
            });
    }

    const fastCreateService = () => {
        if (userDetail.orders && userDetail?.orders[0]?.item_code) {
            dispatch(createInvoiceAction(userDetail.id, activeServicesList.filter(item => item.code === userDetail?.orders[0]?.item_code)[0].id, 0))
        } else {
            swal('KH chưa tạo DV', '', 'warning');
            return;
        }
    };

    const renderComponent = () => {
        if (!loading && userDetail && userDetail.id) {
            return <>
                <Breadcrumb style={{ marginBottom: '20px' }}>
                    <Breadcrumb.Item>
                        <Link to={ROUTERS.HOME}>Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={ROUTERS.READ_USER}>Danh Sách KH</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Chi Tiết KH</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <DeleteTwoTone onClick={(event) => deleteUser(event, userDetail.id)} twoToneColor='red' style={{ fontSize: '25px', marginRight: '1rem' }} />
                    <h1 style={{ fontSize: 30, marginRight: 30 }}>{userDetail?.name}</h1>
                    <h1>Số dư: <b>{parseInt(userDetail.total_paid - userDetail.total_unit_price)?.toLocaleString()}</b></h1>
                </div>

                <Collapse>
                    <CollapsePanel header="Thông tin khách hàng" key="1">
                        <p style={{ marginBottom: 10 }} >Điểm tích lũy</p>
                        <Input style={{ marginBottom: 10 }} value={userDetail.reward_point} disabled />
                        <Form
                            style={{ borderBottom: "1px solid #f0f0f0" }}
                            form={newsForm}
                            initialValues={{
                                name: userDetail?.name,
                                phone: userDetail?.phone,
                                address: userDetail?.address,
                                pathological: userDetail?.pathological,
                                note: userDetail?.note,
                                date_of_birth: moment(userDetail?.date_of_birth),
                                gender: userDetail?.gender
                            }}
                            layout="vertical"
                            onFinish={editUser}
                            scrollToFirstError
                        >
                            <Form.Item
                                className="form-in"
                                label="Tên Khách Hàng"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng điền họ tên vào khung',
                                    },
                                ]}
                            >
                                <Input placeholder="Nhập họ tên của bệnh nhân..." />
                            </Form.Item>
                            <Form.Item
                                className="form-in"
                                label="Giới Tính"
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng chọn giới tính',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Giới Tính"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {Genders.map((item, index) => {
                                        return <Select.Option key={index} value={item}>{item}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                className="form-in"
                                label="Số Điện Thoại"
                                name="phone"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Vui lòng nhập số điện thoại!'
                            //     },
                            //     {
                            //         min: 9,
                            //         message: 'Số điện thoại bạn vừa nhập không đúng định dạng'

                            //     },
                            //     {
                            //         required: true,
                            //         pattern: new RegExp("^[0-9]*$"),
                            //         message: "Số điện thoại chỉ bao gồm số, không bao gồm ký tự khác!"
                            //     }
                            // ]}
                            >
                                <Input placeholder="Nhập số điện thoại" addonBefore="+84" style={{ width: '100%' }} minLength="9" maxLength="10" />
                            </Form.Item>
                            <Form.Item
                                className="form-in"
                                label="Địa Chỉ"
                                name="address"
                            >
                                <Input placeholder="Tòa Nhà, Tên Đường..." />
                            </Form.Item>
                            <Form.Item
                                className="form-in"
                                label="Bệnh Lý"
                                name="pathological"
                            >
                                <Input placeholder="Nhập bệnh lý của bệnh nhân" />
                            </Form.Item>
                            <Form.Item
                                className="form-in"
                                label="Ghi Chú"
                                name="note"
                            >
                                <Input placeholder="Nhập ghi chú của bệnh nhân" />
                            </Form.Item>
                            <Form.Item
                                label="Ngày sinh"
                                className="form-in"
                                name="date_of_birth"
                            >
                                <DatePicker placeholder='Ngày sinh' />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Sửa Khách Hàng</Button>
                            </Form.Item>
                        </Form >
                    </CollapsePanel>
                    <CollapsePanel header="Thêm dịch vụ" key="2">
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '500px', marginBottom: '20px' }}
                            placeholder="Chọn dịch vụ"
                            onChange={(value) => setIDDV(value)}
                        >
                            {activeServicesList.map((item, index) => {
                                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                            })}
                        </Select>
                        <br />
                        <Button type="primary" onClick={createInvoice}>Xác Nhận</Button>
                    </CollapsePanel>
                    <CollapsePanel header="Lịch sử khám" key="3">
                        <Table
                            dataSource={serviceHistory}
                            scroll={{ x: 400 }}
                            pagination={{
                                position: ['topCenter', 'bottomCenter'],
                                defaultCurrent: 1,
                                total: totalHistories,
                                onChange: (value) => setPage(value),
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                onShowSizeChange: (current, pageSize) => setLimit(pageSize)
                            }}
                        >
                            <Column
                                title="Ngày Tái Khám"
                                key="created_at"
                                render={(text, record) => (
                                    moment.utc(record.created_at).utcOffset(7).format('YYYY-MM-DDTHH:mm:ss')
                                )}
                            />
                            <Column title="Tên Dịch Vụ" dataIndex="item_name" key="item_name" />
                            <Column
                                title="Tiến Trình Điều Trị"
                                key="action"
                                render={(text, record) => (
                                    <Space size="middle">
                                        <Tag color="blue">{record.treatment_progress}</Tag>/<Tag color="#2db7f5">{record.total_treatment}</Tag>
                                    </Space>
                                )}
                            />
                            <Column title="Thanh toán" key="history_price"
                                render={(text, record) => (
                                    <p>{parseInt(record.price).toLocaleString()}</p>
                                )}
                            />
                        </Table>
                    </CollapsePanel>
                </Collapse>

                <Button type='primary' style={{ marginTop: 10, marginLeft: 30 }} onClick={fastCreateService}>Tạo DV nhanh</Button>
                {userDetail.orders && userDetail?.orders[0]?.item_code ?
                    <Table
                        scroll={{ x: 400 }}
                        style={{ marginTop: '20px' }}
                        columns={newCollums}
                        dataSource={userOrderList}
                        pagination={false}
                    />
                    :
                    <h1 style={{ textAlign: 'center', border: '1px solid black', padding: 20, marginTop: 20 }}>Khách hàng chưa tạo dịch vụ</h1>
                }

                <Modal title="Điểm Danh Và Thanh Toán" visible={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)}>
                    <h4>Số dư: <b>{parseInt(userDetail.total_paid - userDetail.total_unit_price)?.toLocaleString()}</b></h4>
                    <h4>Tổng tiền: <b>{treatment.totalPrice?.toLocaleString()}</b></h4>
                    <h4>Đã trả: <b>{treatment.paid?.toLocaleString()}</b></h4>
                    <h4>Còn lại: <b>{treatment.unpaid?.toLocaleString()}</b></h4>
                    <Checkbox style={{ marginBottom: 10 }} onChange={onChange}>Không đi</Checkbox>
                    <InputNumber
                        style={{ width: '100%' }}
                        max={10000000}
                        step={1000}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => setPaid(value)}
                    />
                </Modal>
            </>
        } else {
            return <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spin size='large' />
            </div>
        }
    }
    return (
        renderComponent()
    );
};

export default ReadUserDetail;
